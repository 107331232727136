import Loadable from "../../components/Loadable";

const DaftarPresensi = Loadable(() => import("./DaftarPresensi/DaftarPresensi"));
const DetilPresensi = Loadable(() => import("./DaftarPresensi/DetilPresensi"));
const PresensiPegawai = Loadable(() => import("./PresensiPegawai/PresensiPegawai"));
const DetilPresensiPegawai = Loadable(() => import("./PresensiPegawai/DetilPresensiPegawai"));

export default {
	DetilPresensiPegawai,
	PresensiPegawai,
	DetilPresensi,
	DaftarPresensi,
};
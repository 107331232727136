import Loadable from "../../components/Loadable";

const DaftarAkunPegawai = Loadable(() => import("./DaftarAkunPegawai/DaftarAkunPegawai"));
const DetilAkunPegawai = Loadable(() => import("./DaftarAkunPegawai/DetilAkunPegawai"));
const TambahPegawai = Loadable(() => import("./DaftarAkunPegawai/TambahPegawai"));
const PenguncianAkses = Loadable(() => import("./PenguncianAkses/PenguncianAkses")); 
const PengaturanVariabel = Loadable(() => import("./PengaturanVariabel/PengaturanVariabel")); 
const TemplateSK = Loadable(() => import("./TemplateSK/TemplateSK")); 
const DetilTemplateSK = Loadable(() => import("./TemplateSK/DetilTemplateSK")); 
const TambahTemplateSK = Loadable(() => import("./TemplateSK/TambahTemplateSK")); 
const PengaturanPresensi = Loadable(() => import("./PengaturanPresensi/PengaturanPresensi")); 
const DaftarPengelolaan = Loadable(() => import("./Pengelolaan/DaftarPengelolaan"));
const DaftarPengelolaanYayasan = Loadable(() => import("./Pengelolaan/DaftarPengelolaanYayasan"));
const TambahUnit = Loadable(() => import("./Pengelolaan/TambahUnit"));
const DetilPengelolaan = Loadable(() => import("./Pengelolaan/DetilPengelolaan"));


export default {
	DetilAkunPegawai,
	PengaturanVariabel,
	TambahTemplateSK,
	DetilTemplateSK,
	PengaturanPresensi,
	TemplateSK,
	PenguncianAkses,
	DaftarAkunPegawai,
	DetilPengelolaan,
	TambahUnit,
	DaftarPengelolaanYayasan,
	DaftarPengelolaan,
	TambahPegawai,
};
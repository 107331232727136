import _ from 'lodash';
import styled from 'styled-components';
// import { Button } from 'semantic-ui-react';
import { Redirect, Route, Switch } from 'react-router-dom';
import React, { useContext } from 'react';

import bkgpattern from '../assets/bkgpattern.svg'
import logo_sekolah_mini from '../assets/logo_sekolah_mini.png';
import Sidebar from '../components/Sidebar';

import { UserContext } from '../context';
import { Header, Icon, Image } from 'semantic-ui-react';
import { useState } from 'react';
import Media from 'react-media';

// import logo_impuls from '../assets/logo_impuls.png';
// import logo_sekolah from '../assets/logo_sekolah.png';
// import UnderMaintenance from './Public/UnderMaintenance';

const Layout = styled.div`
	display: grid;
	position: absolute;
	width: 100%;
	height: 100%;
	grid-template-columns: auto 1fr;

	> .top-navigation { display: none; }

	.input-container input {
    border: none;
    box-sizing: border-box;
    outline: 0;
    padding: .75rem;
    position: relative;
    width: 100%;
	}

	input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height: auto;
    width: auto;
	}

	input[type="date"]::-webkit-inner-spin-button {
		display: none;
		-webkit-appearance: none;
	}
	
	@media only screen and (max-width: 768px) {
		grid-template-rows: auto 1fr;
		> .responsiveSVG {
			grid-column: span 2;
			width: 100%;
			background-color: #FAFEFB;
			background-image: url(${bkgpattern});
			background-repeat: repeat;
			background-size: 400px;
		}
	}
	@media only screen and (min-width: 768px) {
		
	}
	@media only screen and (min-width: 992px) {
				
	}
	@media only screen and (min-width: 1600px) {
		
	}
`;

const Content = styled.div`
	flex: 1;
	display: flex;	
	overflow: auto;
	padding: 1.5rem 1.5rem 0 1.5rem;
	
	@media only screen and (max-width: 768px) {
		padding: 0;
	}
	`;

const ContainerSekolah = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: auto auto 1fr;
	padding: 1em;
	grid-gap: .7em;

	.hover:hover {
		cursor: pointer;
	}
`

const Dashboard = ({ location }) => {
	const session = useContext(UserContext);
	const [tutup, setTutup] = useState(true);

	const uri		= _.get(location, "pathname", "");
	const { list_menu = [], list_route } = session;
	const default_to = "/profil/biodata";
	const path = {
		atur: "pengaturan-sistem",
		pegawai: "kepegawaian"
	};

	const breakRoute = (route) => {
		return route.split("/")?.[1] || "";
	};
	
	const breakPath = (path) => {
		return path.split("#")?.[0]?.toLowerCase() || "";
	};

	const convert = (args) => {
		return path?.[args] || args;
	};

	const checkRoute = (route) => {
		const check = (session?.acl || []).findIndex(({ id }) => {
			return convert(breakPath(id)) === breakRoute(route);
		});

		return check !== -1;
	};
	
	if (session.is_login && !checkRoute(uri)) {
		return <Redirect to="/profil/biodata" />
	}

	if (!session.is_login) {
		const search = new URLSearchParams();
		if (!_.isEmpty(uri) && uri !== '/') {
			search.append('referer', uri);
		}
		const redirectionURI = `/login?${search.toString()}`;
		return <Redirect to={redirectionURI} />;
	}

	// if (session.is_login && perbaikan) { return <UnderMaintenance nama={nama_sekolah}/>}
	
	// console.log(session, list_route)
	// console.log('list_route', list_route);
	// console.log('location', session.is_login, location);
	
	return (<Layout>
		<Media query={{ maxWidth: 768 }}>
			{v => v && <div className="responsiveSVG">
			<ContainerSekolah tutup={tutup}>
				<div className="hover"><Icon name="bars" onClick={() => setTutup(!tutup)}/></div>
				<div><Image width="45px" src={logo_sekolah_mini}/></div>
				<div><Header color="green" as="h4">
					Sistem Informasi Manajemen
					<Header.Subheader>Yayasan Nur Hidayah Surakarta</Header.Subheader>
				</Header></div>
			</ContainerSekolah>
		</div>} 
		</Media>
		<Media query={{ maxWidth: 768 }}>
			{v => v 
				? tutup ? "" : <Sidebar tutup={tutup} list_menu={list_menu} location={location} /> 
				: <Sidebar tutup={tutup} list_menu={list_menu} location={location} /> 
			} 
		</Media>
		<Content>
			<Switch>
				{_.toArray(list_route).map((item, index) => <Route exact key={index} {...item} />)}
				<Route key="DEFAULT" path="/" component={() => {
					// console.log('#2 Redirect to Top Level Menu')
					return <Redirect to={default_to} />
				}}/>
			</Switch>
		</Content>
	</Layout>);
};

export default Dashboard;
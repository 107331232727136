import _ from "lodash"
import styled from 'styled-components';
import React, { Component } from 'react';
import logo_sekolah_mini from '../assets/logo_sekolah_mini.png';
import pp_karyawan from '../assets/pp_karyawan.png';
import { Accordion, Header, Icon, Image, Menu } from 'semantic-ui-react';

import bkgpattern from '../assets/bkgpattern.svg'
import { Link } from "react-router-dom";
import { asset } from "../fetcher";
import { UserContext } from '../context';
import Media from "react-media";

const MainContainer = styled.div`
	height: 100vh;
	display: grid;
	width: ${({tutup}) => tutup ? "84px" : "280px"};
	background-color: #FAFEFB;
	background-image: url(${bkgpattern});
	background-repeat: repeat;
	background-size: 400px;
	grid-template-rows: auto auto 1fr auto;

	@media only screen and (max-width: 768px) {
		width: ${({tutup}) => tutup ? "0px" : "280px"};
	}
`
const ContainerSekolah = styled.div`
	display: grid;
	align-items: center;
	grid-template-columns: ${({tutup}) => tutup ? "1fr" : "auto 1fr"};
	padding: 1em;
	grid-gap: 1em;
	justify-items: ${({tutup}) => tutup ? "" : ""};

	.hover:hover {
		cursor: pointer;
	}
`
const ContainerListMenu = styled.div`
	padding-right: .5em;
	padding-left: 1.3em;
	overflow-y: hidden;

	div {
		width: ${({tutup}) => tutup ? "52px" : "auto"};
	}

	.ui.secondary.menu .item {
		align-self: initial;
	}

	:hover {
		overflow-y: auto;
		overflow-x: hidden;
	}

	@media only screen and (max-width: 768px) {
		overflow-x: hidden;
		width: ${({tutup}) => tutup ? "0px" : "auto"};
		
	}
`
const GridMenuContent = styled.div`
	display: flex; 
	flex-direction: column;
`
// const listMenuInitial = [
// 	{id: "Dashboard", icon: "columns", child: []},
// 	{id: "Keorganisasian", icon: "sitemap", child: [
// 		{nama: "Daftar Nilai", url: "/"}, 
// 		{nama: "Daftar Kumulatif Nilai", url: "/"}, 
// 		{nama: "Daftar Peringkat", url: "/"},]},
// 	{id: "Kepegawaian", icon: "users", child: [
// 		{nama: "Biodata Pegawai", url: "/"}, 
// 		{nama: "Riwayat Kepegawaian", url: "/"}, 
// 		{nama: "Catak Rapor", url: "/"}, 
// 		{nama: "Rekap Nilai Ujian Sekolah", url: "/"}, 
// 		{nama: "Dokumen Kelulusan", url: "/"}, 
// 		{nama: "Rekap Nilai Rapor", url: "/"}, 
// 		{nama: "Pengaturan Rapor", url: "/"},]},
// 	{id: "Presensi Pegawai", icon: "clipboard check", child: [
// 		{nama: "Daftar Mapel & KD", url: "/"}, 
// 		{nama: "Daftar Tinggal Kelas", url: "/"}, 
// 		{nama: "Parameter Sikap", url: "/"},]},
// 	{id: "Pengaturan Sistem", icon: "print", child: [
// 		{nama: "Nilai Ekskul", url: "/"},
// 		{nama: "Daftar Ekskul", url: "/"},
// 	]},
// 	{id: "John Doe", icon: "user", child: [
// 		{nama: "Profil", url: "/"},
// 		{nama: "Logout/ Keluar", url: "/"},
// 	]},
// ]

const AccordionTitle = ({ foto_akun, activeIndex, index, original }) => {
	return <div style={{display: "grid", gridTemplateColumns: "auto 1fr auto", alignItems: 'center'}}>
		{_.isEmpty(original.icon) 
			? <div><Image style={{height: "auto", width: "24px"}} src={foto_akun} avatar /></div>
			: <div><Icon style={{fontSize: "1.2em"}} name={original.icon} color={activeIndex === index ? "green" : "black"}/></div>
		}
		<div style={{display: "grid", gridTemplateColumns: "auto auto", alignItems: 'center', margin: ".5em"}}>
			<div><Header as="h5" color={activeIndex === index ? "green" : "black"} style={{fontWeight: 400, textTransform: "capitalize"}}>{original.id}</Header></div>
			{/* <div style={{display: "grid", alignItems: 'center'}}>{activeIndex === i && <Icon color='orange' size="tiny" name="star" />}</div> */}
		</div>
		{!_.isEmpty(original.child) && <Icon name={"dropdown"} size="mini" color={activeIndex === index ? "green" : "black"}/>}
	</div>
}

const MenuContentAcc = ({ child, handleClick, activeMenu }) => {
	return <GridMenuContent>
		{child.map((o) => {
			const validateTO = _.isEmpty(o.url)
			return <Link style={{textDecoration: "none"}} to={o.url}><Menu.Item style={{ marginBottom: ".5em"}} secondary name={o.nama} onClick={validateTO ? o.onClick : handleClick} active={o.nama === activeMenu}>
				<div style={{display: "grid", gridTemplateColumns: "auto auto", alignItems: 'center', marginLeft: ".5em"}}>
					<div style={{marginLeft: ".8em"}}><Header as="h5" color={activeMenu === o.nama && "green"} style={{fontWeight: 400, textTransform: "capitalize"}}>{o.nama}</Header></div>
					{/* <div style={{display: "grid", alignItems: 'center'}}>{activeMenu === o.nama && <Icon color='orange' size="tiny" name="star" />}</div> */}
				</div>
			</Menu.Item></Link>
		})}
	</GridMenuContent>
}
class SideBar extends Component {
	state = {
		tutup: false,
		activeIndex: "",
		listMenu: [],
		activeMenu: "",
		onMouse: false,
		sideSurat: this.context.sideSurat
	}
	/**
	 * Func
	 */
	handleClick = () => {
		this.setState({tutup: !this.state.tutup, onMouse: !this.state.tutup });
	}
	handleItemClick = (e, { name }) => this.setState({ activeMenu: name})
	handleClickAcc = (e, props) => {
    const { index } = props
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex, tutup: false})
  }

	componentDidMount(){
		const { list_menu, location } = this.props
		const { organisasi } = this.context
		const uri	= _.get(location, "pathname", "");
		let activeIndex = list_menu.findIndex(({ parent }) => parent.to === "/profil/biodata");
		let activeMenu = "Biodata Anda";

		const listMenu = list_menu.map((o, i) => {
			return {
				id: _.get(o, "parent.menu", ""),
				icon: _.get(o, "parent.logo", ""),
				parent_to: _.get(o, "parent.to", "/"),
				child: _.get(o, "child", []).map(oo => {
					let url = _.get(oo, "to", "")
					const id_organisasi = _.get(organisasi, "_id", "")
					if(_.includes(uri, _.get(oo, "to", "")) && !_.isEmpty(_.get(oo, "to", "") )){
						activeIndex = i
						activeMenu = _.get(oo, "menu", "")
					}
					if(_.get(oo, "to", "") === "/pengaturan-sistem/pengelolaan-yayasan") {
						url = `/pengaturan-sistem/pengelolaan-yayasan/${id_organisasi}`
					}
					return ({nama: _.get(oo, "menu", "") , url, onClick: _.get(oo, "onClick", () => null) })
				}),
			}
		})

		return this.setState({ listMenu, activeIndex, activeMenu });
	}

	componentDidUpdate(pp, ps){
		const { tutup } = this.props
		const { sideSurat } = this.context

		if(tutup !== pp.tutup){
			this.setState({tutup});
		}

		if(!_.isEqual(ps.sideSurat, sideSurat)) {
			const getMenuSurat = (url) => _.capitalize(url.replace("/surat/", "").replace("-", " ")) 
			const getTotalSurat = (url) => {
				const menu = getMenuSurat(url);
				const name = _.snakeCase(menu.toLowerCase())
				const total = sideSurat[name] || 0

				return total !== 0 ? `(${total})` : ""
			}

			const listMenu = ps.listMenu.map((item) => item?.parent_to === "/surat" ? (
				{
					...item,
					id: `Surat Menyurat ${getTotalSurat("/surat/total")}`,
					child: item.child.map((item) => ({ ...item, nama: `${getMenuSurat(item.url)} ${getTotalSurat(item.url)}` }))
				}
			) : item)

			this.setState({ listMenu, sideSurat });
		}
	}
	/**
	 * React Rendering
	 */
	render() {
		const { user } = this.context
		const { tutup, activeIndex, listMenu, activeMenu, onMouse } = this.state
		const foto_akun = !_.isEmpty(_.get(user, "foto_asset_id", "")) ? asset.getURL(_.get(user, "foto_asset_id", "")) : pp_karyawan

		return (
			<MainContainer tutup={tutup}>
				<Media query={{ maxWidth: 768 }}>
					{v => !v && <>
						<ContainerSekolah tutup={tutup}>
							<div><Image width="52px" src={logo_sekolah_mini}/></div>
							{!tutup && <div><Header color="green" as="h3">Sistem Informasi Manajemen</Header></div>}
						</ContainerSekolah>
						<ContainerSekolah tutup={tutup} style={{paddingTop: "0", paddingBottom: "0", marginBottom: "1em", paddingLeft: "1.3em", height: "44px"}}>
							<div className="hover" style={{marginLeft: ".8em"}} onClick={this.handleClick}><Icon name="bars" /></div> 
							{/* {tutup 
								? <div className="hover" style={{marginLeft: ".7em"}} onClick={this.handleClick}><Icon name="arrow right" /></div> 
								: <div className="hover" style={{marginLeft: ".6em"}} onClick={this.handleClick}><Icon name="arrow left" /></div>
							} */}
							{!tutup && <div>Yayasan Nur Hidayah Surakarta</div>}
						</ContainerSekolah>
					</>}
				</Media>
				<ContainerListMenu style={{paddingTop: "0"}} tutup={tutup} onMouseLeave={() => this.setState({tutup: onMouse})}>
					{<div>
						<Accordion as={Menu} style={{width: "auto"}} secondary vertical onMouseOver={() => this.setState({tutup: false})}>
							{listMenu.map((o, i)=> {
								let propsAccTitle = {
									index : i,
									name : o.id,
									active : activeIndex === i,
									onClick : this.handleClickAcc,
								}
								return <Menu.Item style={{paddingLeft: "1em", paddingRight:".7em", backgroundColor: activeIndex === i ? "white" : "" }}>
									{ tutup 
										? <Accordion.Title {...propsAccTitle}>
											{_.isEmpty(o.icon) 
												? <div><Image style={{height: "24px", width: "auto"}} src={foto_akun} avatar /></div>
												: <div><Icon style={{fontSize: "1.2em"}} name={o.icon} color={activeIndex === i ? "green" : ""}/></div>
											}
										</Accordion.Title>
										: <><Accordion.Title {...propsAccTitle}>
												{_.isEmpty(o.child) 
													? <Link to={o.parent_to} style={{ textDecoration: "none" }}><AccordionTitle {...{foto_akun, activeIndex, index: i, original: o}} /></Link> 
													: <AccordionTitle {...{foto_akun, activeIndex, index: i, original: o}} />
												}
											</Accordion.Title>
											{!_.isEmpty(o.child) && <Accordion.Content active={activeIndex === i}>
												<MenuContentAcc child={o.child} activeMenu={activeMenu} handleClick={this.handleItemClick} />
											</Accordion.Content>}
										</>
									}
								</Menu.Item>
							})}
						</Accordion>	
					</div>}
				</ContainerListMenu>
			</MainContainer>
		);
	}
}

SideBar.contextType = UserContext

export default SideBar;
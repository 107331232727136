import Loadable from "../../components/Loadable";

const SuratUnit = Loadable(() => import("../Surat/SuratUnit/index.jsx"));
const SuratAnda = Loadable(() => import("../Surat/SuratAnda/index.jsx"));
const SuratMasuk = Loadable(() => import("../Surat/SuratMasuk/index.jsx"));
const SuratKeluar = Loadable(() => import("../Surat/SuratKeluar/index.jsx"));
const DaftarSurat = Loadable(() => import("../Surat/DaftarSurat/index.jsx"));
const SuratMasukBaru = Loadable(() => import("../Surat/SuratMasuk/Baru.jsx"));
const SuratKeluarBaru = Loadable(() => import("../Surat/SuratKeluar/Baru.jsx"));
const DaftarSuratID = Loadable(() => import("../Surat/DaftarSurat/ID"));
const SuratAndaID = Loadable(() => import("../Surat/SuratAnda/ID.jsx"));
const SuratMasukID = Loadable(() => import("../Surat/SuratMasuk/ID.jsx"));
const SuratKeluarID = Loadable(() => import("../Surat/SuratKeluar/ID.jsx"));
const SuratMasukEdit = Loadable(() => import("../Surat/SuratMasuk/Edit.jsx"));
const SuratKeluarEdit = Loadable(() => import("../Surat/SuratKeluar/Edit.jsx"));
const SuratKeluarArsip = Loadable(() => import("../Surat/SuratKeluar/Arsip.jsx"));

const Surat = {
  SuratUnit,
  SuratAnda,
  SuratMasuk,
  SuratKeluar,
  DaftarSurat,
  SuratMasukBaru,
  SuratKeluarBaru,
  DaftarSuratID,
  SuratAndaID,
  SuratMasukID,
  SuratKeluarID,
  SuratMasukEdit,
  SuratKeluarEdit,
  SuratKeluarArsip
}

export default Surat;
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Header } from 'semantic-ui-react';

import { UserContext } from '../../context';

class ForgetForm extends Component {
	render() {
		const { handleClick } = this.props
		const { list_admin_sistem } = this.context
		const three_array = _.chain(list_admin_sistem).filter(o => !_.isEmpty(_.get(o, "email", null))).take(3).value()

		return (<div>
			<Header as="h3" color="green">Anda lupa password?</Header>
			<p>
				Jika Anda lupa password, hubungi  salah satu Administrator Sistem berikut untuk membantu me-reset password Anda : <br /> 
				<ul>
					{_.isEmpty(three_array) 
						? <b>Data Tidak Ditemukan</b>
						: three_array.map(o => {
							const email = _.get(o, "email", null) ? <a href={`mailto:${o.email}`}>{o.email}</a> : null
							const nama = _.get(o, "nama", null)
							return <li>{nama}{email && " | "}{email}</li>
						})
					}
				</ul> <br />
				Terima kasih.
			</p>
			<Button name="kembali" fluid basic primary icon="arrow left" iconPosition="left" content=" Kembali" onClick={handleClick}/>
		</div>);
	}
}

ForgetForm.contextType = UserContext

ForgetForm.propTypes = {
	handleClick: PropTypes.func
};

export default ForgetForm;
import _ from 'lodash';

const NAMA_SEKOLAH = "DEFAULT_NAMA_SEKOLAH";
const JENJANG = "JEN"
const JENJANG_SEBELUMNYA = "JS"
const JENJANG_SEBELUMNYA_KECIL = JENJANG_SEBELUMNYA.toLowerCase();
const CONTACT_IMPULS = "081228800048";

const LOGIN_FORM = 'LOGIN_FORM';
const FORGET_FORM = 'FORGET_FORM';
const DEFAULT_KKM = 50;
const DEFAULT_DIFF = 10;
const MAX_FILE_UPLOAD_SIZE = 5;

const MAP_LEVEL = { C: "create", R: "read", U: "update", D: "delete" };

const LIST_VARIABEL = [
	//Data Surat Keputusan
		{label: "No. SK", 
			kode: "{{sk_nomor}}", 
			deskripsi: "Isian Nomor Surat Keputusan", 
			contoh: "161/SK/YNH/X/2021"},
	
		{label: "Judul SK", 
			kode: "{{sk_judul}}", 
			deskripsi: "Isian Judul Surat Keputusan", 
			contoh: "PENYESUAIAN GOLONGAN PEGAWAI YAYASAN NUR HIDAYAH DI UNIT KANTOR PUSAT YAYASAN NUR HIDAYAH SURAKARTA"},
	
		{label: "Tanggal Mulai", 
			kode: "{{tanggal_mulai}}", 
			deskripsi: "Isian tanggal mulai berlakunya Surat Keputusan", 
			contoh: "1 Januari 2021"},
	
		{label: "Tanggal Selesai", 
			kode: "{{tanggal_selesai}}", 
			deskripsi: "Isian tanggal selesai berlakunya Surat Keputusan", 
			contoh: "1 Januari 2022"},
		{label: "Sapaan",  
			kode: "{{sapaan}}", 
			deskripsi: "Sapaan sesuai jenis kelamin", 
			contoh: "Saudara"},
	
	//Data Pegawai
		{label: "Nama Pegawai", 
			kode: "{{pegawai_nama}}", 
			deskripsi: "Menampilkan nama lengkap pegawai yang bersangkutan", 
			contoh: "Jasmin Utami"},
	
		{label: "Tempat Tanggal Lahir Pegawai", 
			kode: "{{pegawai_ttl}}", 
			deskripsi: "Menampilkan tempat dan tanggal lahir pegawai ", 
			contoh: "Surakarta, 15 Agustus 1987"},
	
		{label: "Tanggal Mulai Bertugas Baru Pegawai", 
			kode: "{{pegawai_tmt_baru}}", 
			deskripsi: "Menampilkan tanggal mulai tugas pegawai yang bersangkutan", 
			contoh: "15 Agustus 2020"},
			
		{label: "Tanggal Mulai Bertugas Lama Pegawai", 
			kode: "{{pegawai_tmt_lama}}", 
			deskripsi: "Menampilkan tanggal mulai tugas lama pegawai sebelum diganti menjadi yang baru", 
			contoh: "15 Agustus 2020"},
	
		{label: "Tanggal Mulai Bertugas Mutasi Baru Pegawai", 
			kode: "{{pegawai_tmt_mutasi_baru}}", 
			deskripsi: "Menampilkan tanggal mulai tugas mutasi pegawai yang bersangkutan", 
			contoh: "15 Agustus 2020"},
			
		{label: "Tanggal Mulai Bertugas Mutasi Lama Pegawai", 
			kode: "{{pegawai_tmt_mutasi_lama}}", 
			deskripsi: "Menampilkan tanggal mulai tugas mutasi lama pegawai sebelum diganti menjadi yang baru", 
			contoh: "15 Agustus 2020"},
	
	//Data Kepegawaian yang Berubah
		{label: "NIPY Lama Pegawai", 
			kode: "{{pegawai_nipy_lama}}", 
			deskripsi: "Menampilkan NIPY lama pegawai sebelum diganti menjadi yang baru", 
			contoh: "12.04.03.258"},
	
		{label: "NIPY Baru Pegawai ", 
			kode: "{{pegawai_nipy_baru}}", 
			deskripsi: "Isian NIPY baru yang akan menggantikan NIPY lama", 
			contoh: "12.04.03.259"},
	
		{label: "Status Kepegawaian Lama Pegawai", 
			kode: "{{pegawai_status_lama}}", 
			deskripsi: "Menampilkan Status Kepegawaian pegawai sebelum diganti menjadi yang baru", 
			contoh: "Calon Pegawai"},
	
		{label: "Status Kepegawaian Baru Pegawai", 
			kode: "{{pegawai_status_baru}}", 
			deskripsi: "Isian Status Kepegawaian baru yang akan menggantikan Status Kepegawaian lama", 
			contoh: "Pegawai Tetap Yayasan"},
	
		{label: "Gaji Pokok Lama Pegawai", 
			kode: "{{pegawai_gaji_lama}}", 
			deskripsi: "Menampilkan Gaji pegawai sebelum diganti menjadi yang baru", 
			contoh: "Rp2.000.000"},
	
		{label: "Gaji Pokok Baru Pegawai", 
			kode: "{{pegawai_gaji_baru}}", 
			deskripsi: "Isian Gaji baru yang akan menggantikan Gaji lama", 
			contoh: "Rp2.500.000"},
	
		{label: "Golongan/Ruang Lama Pegawai", 
			kode: "{{pegawai_golongan_lama}}", 
			deskripsi: "Menampilkan Golongan/Ruang pegawai sebelum diganti menjadi yang baru", 
			contoh: "II/A"},
	
		{label: "Golongan/Ruang Baru Pegawai", 
			kode: "{{pegawai_golongan_baru}}", 
			deskripsi: "Isian Golongan/Ruang pegawai baru yang akan menggantikan Golongan/Ruang lama", 
			contoh: "II/B"},
	
		{label: "Pangkat Lama Pegawai", 
			kode: "{{pegawai_pangkat_lama}}", 
			deskripsi: "Menampilkan Pangkat pegawai sebelum diganti menjadi yang baru", 
			contoh: "Muda"},
	
		{label: "Pangkat Baru Pegawai", 
			kode: "{{pegawai_pangkat_baru}}", 
			deskripsi: "Isian Pangkat pegawai baru yang akan menggantikan Pangkat lama", 
			contoh: "Madya"},
	
		{label: "Tugas Pokok & Fungsi Lama Pegawai", 
			kode: "{{pegawai_tupoksi_lama}}", 
			deskripsi: "Menampilkan Tugas Pokok & Fungsi pegawai sebelum diganti menjadi yang baru", 
			contoh: "Karyawan"},
	
		{label: "Tugas Pokok & Fungsi Baru Pegawai", 
			kode: "{{pegawai_tupoksi_baru}}", 
			deskripsi: "Isian Tugas Pokok & Fungsi pegawai baru yang akan menggantikan Tugas Pokok & Fungsi lama", 
			contoh: "Guru"},
	
		{label: "Tugas Tambahan Lama Pegawai", 
			kode: "{{pegawai_tugas_tambahan_lama}}", 
			deskripsi: "Menampilkan Tugas Tambahan pegawai sebelum diganti menjadi yang baru", 
			contoh: "Staf Pengajar"},
	
		{label: "Tugas Tambahan Baru Pegawai", 
			kode: "{{pegawai_tugas_tambahan_baru}}", 
			deskripsi: "Isian Tugas Tambahan pegawai baru yang akan menggantikan Tugas Tambahan lama", 
			contoh: "Wali Kelas"},

		{label: "Detil Tugas Tambahan Lama Pegawai", 
			kode: "{{pegawai_detil_tugas_tambahan_lama}}", 
			deskripsi: "Menampilkan Detil Tugas Tambahan pegawai sebelum diganti menjadi yang baru", 
			contoh: "Kelas X-A"},
	
		{label: "Detil Tugas Tambahan Baru Pegawai", 
			kode: "{{pegawai_detil_tugas_tambahan_baru}}", 
			deskripsi: "Isian Detil Tugas Tambahan pegawai baru yang akan menggantikan Detil Tugas Tambahan lama", 
			contoh: "Kelas X!-B"},
	
		{label: "Unit Kerja Lama Pegawai", 
			kode: "{{pegawai_unit_kerja_lama}}", 
			deskripsi: "Menampilkan Unit Kerja pegawai sebelum diganti menjadi yang baru", 
			contoh: "SMPIT Nur Hidayah"},
	
		{label: "Unit Kerja Baru Pegawai", 
			kode: "{{pegawai_unit_kerja_baru}}", 
			deskripsi: "Isian Unit Kerja pegawai baru yang akan menggantikan Unit Kerja lama", 
			contoh: "SMAIT Nur Hidayah"},

		{label: "Tanggal Habis Pangkat Baru", 
			kode: "{{pegawai_tanggal_habis_pangkat_baru}}", 
			deskripsi: "Tanggal akhir berlakunya Pangkat Pegawai", 
			contoh: "15 Agustus 2020"},

		{label: "Tanggal Habis Pangkat Lama", 
			kode: "{{pegawai_tanggal_habis_pangkat_lama}}", 
			deskripsi: "Tanggal akhir berlakunya Pangkat Pegawai", 
			contoh: "15 Agustus 2020"},

		{label: "Tanggal Habis Golongan/Ruang Baru", 
			kode: "{{pegawai_tanggal_habis_golongan_ruang_baru}}", 
			deskripsi: "Tanggal akhir berlakunya Golongan/Ruang Pegawai", 
			contoh: "15 Agustus 2020"},

		{label: "Tanggal Habis Golongan/Ruang Lama", 
			kode: "{{pegawai_tanggal_habis_golongan_ruang_lama}}", 
			deskripsi: "Tanggal akhir berlakunya Golongan/Ruang Pegawai", 
			contoh: "15 Agustus 2020"},
	
	//Tanda Tangan SK
		{label: "Nama Penanda Tangan", 
			kode: "{{ttd_nama}}", 
			deskripsi: "Isian penanda tangan Surat Keterangan", 
			contoh: "Genta Hasanah, S.Ag, M.Pd.I"},
	
		{label: "Jabatan Penanda Tangan", 
			kode: "{{ttd_jabatan}}", 
			deskripsi: "Isian jabatan penanda tangan Surat Keterangan", 
			contoh: "Ketua Umum Badan Pengurus Yayasan Nur Hidayah Surakarta"},

		{label: "NIPY Penanda Tangan", 
			kode: "{{ttd_nipy}}", 
			deskripsi: "Isian NIPY penanda tangan Surat Keterangan", 
			contoh: "12.04.03.888"},
	
		{label: "Kota di Tanda Tangan", 
			kode: "{{ttd_kota}}", 
			deskripsi: "Isian kota yang ditampilkan diatas tanda tangan Surat Keterangan", 
			contoh: "Surakarta"},
	
		{label: "Tanggal di Tanda Tangan", 
			kode: "{{ttd_tanggal_masehi}}", 
			deskripsi: "Isian tanggal saat Surat Keterangan ditandatangani", 
			contoh: "20 Juli 2021"},
	
		{label: "Tanggal Hijriah di Tanda Tangan", 
			kode: "{{ttd_tanggal_hijriah}}", 
			deskripsi: "Isian tanggal dalam versi Hijriah saat Surat Keterangan ditandatangani", 
			contoh: "11 Sapar 1443H"},
	
		// {label: "Daftar Tembusan", 
		// 	kode: "{{daftar_tembusan}}", 
		// 	deskripsi: "Untuk mengisi Daftar Tembusan",
		// 	contoh: "1. Ketua Yayasan Nur Hidayah Surakarta <br> 2. Bendahara yayasan Nur Hidayah Surakarta <br> 3. Kepala Sekolah menengah Atas Islam Terpadu (SMA-IT) Nur Hidayah Kartasura <br> 4. Arsip"},
]

const TEMPLATE_SK_UNIT = [
	{label: "No. SK", 
			kode: "{{sk_nomor}}", 
			deskripsi: "Isian Nomor Surat Keputusan", 
			contoh: "161/SK/YNH/X/2021"},
	
		{label: "Judul SK", 
			kode: "{{sk_judul}}", 
			deskripsi: "Isian Judul Surat Keputusan", 
			contoh: "PENYESUAIAN GOLONGAN PEGAWAI YAYASAN NUR HIDAYAH DI UNIT KANTOR PUSAT YAYASAN NUR HIDAYAH SURAKARTA"},
	
		{label: "Tanggal Mulai", 
			kode: "{{tanggal_mulai}}", 
			deskripsi: "Isian tanggal mulai berlakunya Surat Keputusan", 
			contoh: "1 Januari 2021"},
	
		{label: "Tanggal Selesai", 
			kode: "{{tanggal_selesai}}", 
			deskripsi: "Isian tanggal selesai berlakunya Surat Keputusan", 
			contoh: "1 Januari 2022"},
		{label: "Tugas Tambahan Baru Pegawai", 
			kode: "{{pegawai_tugas_tambahan_baru}}", 
			deskripsi: "Isian Tugas Tambahan pegawai baru yang akan menggantikan Tugas Tambahan lama", 
			contoh: "Wali Kelas"},
		{label: "Detil Tugas Tambahan Baru Pegawai", 
			kode: "{{pegawai_detil_tugas_tambahan_baru}}", 
			deskripsi: "Isian Detil Tugas Tambahan pegawai baru yang akan menggantikan Detil Tugas Tambahan lama", 
			contoh: "Kelas X!-B"},
		{label: "Nama Penanda Tangan", 
			kode: "{{ttd_nama}}", 
			deskripsi: "Isian penanda tangan Surat Keterangan", 
			contoh: "Genta Hasanah, S.Ag, M.Pd.I"},
		{label: "Jabatan Penanda Tangan", 
			kode: "{{ttd_jabatan}}", 
			deskripsi: "Isian jabatan penanda tangan Surat Keterangan", 
			contoh: "Ketua Umum Badan Pengurus Yayasan Nur Hidayah Surakarta"},

		{label: "NIPY Penanda Tangan", 
			kode: "{{ttd_nipy}}", 
			deskripsi: "Isian NIPY penanda tangan Surat Keterangan", 
			contoh: "12.04.03.888"},
	
		{label: "Kota di Tanda Tangan", 
			kode: "{{ttd_kota}}", 
			deskripsi: "Isian kota yang ditampilkan diatas tanda tangan Surat Keterangan", 
			contoh: "Surakarta"},
	
		{label: "Tanggal di Tanda Tangan", 
			kode: "{{ttd_tanggal_masehi}}", 
			deskripsi: "Isian tanggal saat Surat Keterangan ditandatangani", 
			contoh: "20 Juli 2021"},
	
		{label: "Tanggal Hijriah di Tanda Tangan", 
			kode: "{{ttd_tanggal_hijriah}}", 
			deskripsi: "Isian tanggal dalam versi Hijriah saat Surat Keterangan ditandatangani", 
			contoh: "11 Sapar 1443H"}
]

const OPTS_STATUS_PEGAWAI = ["Calon Pegawai", "Pegawai Unit/Sekolah", "Pegawai Magang Organisasi", "Pegawai Tidak Tetap Yayasan", "Pegawai Tetap Yayasan"].map((o, i)=> ({
	no: i+1,
	key: _.snakeCase(o).toUpperCase(),
	value: o,
	text: o,
}));

const OPTS_GOLONGAN_RUANG = ["II/A", "II/B", "II/C", "II/D", "III/A", "III/B", "III/C", "III/D", "IV/A", "IV/B", "IV/C", "IV/D"].map((o, i)=> ({
	no: i+1,
	key: _.snakeCase(o).toUpperCase(),
	value: o,
	text: o,
}));

const OPTS_PANGKAT = ["Pemula/Junior", "Muda", "Madya", "Pratama"].map((o, i)=> ({
	no: i+1,
	key: _.snakeCase(o).toUpperCase(),
	value: o,
	text: o,
}));

const OPTS_TUGAS_POKOK = ["Pengurus Badan Pelaksana", "Staf Kantor Pusat", "Staf Bidang Sosial", "Tata Usaha", "Guru Mapel", "Guru Kelas", "Guru Bimbingan dan Koseling", "Perpustakaan", "Rumah Tangga", "Security", "Driver"].map((o, i)=> ({
	no: i+1,
	key: _.snakeCase(o).toUpperCase(),
	value: o,
	text: o,
}));

const OPTS_TUGAS_TAMBAHAN = ["Kepala Sekolah", "Wakil Kepala Sekolah", "Kepala Tata Usaha", "Koordinator Al Qur'an (SDIT)", "Wali Kelas", "Staf Wakil Kepala Sekolah", "Lainnya"].map((o, i)=> ({
	no: i+1,
	key: _.snakeCase(o).toUpperCase(),
	value: o,
	text: o,
}));


const OPTS_JENIS_KELAMIN = 'Laki-laki Perempuan'.split(' ').map(o => ({
	key: _.snakeCase(o).toUpperCase(),
	value: o,
	text: o,
}));

const OPTS_JENJANG_PENDIDIKAN = 'TK SD SMP SMA UNIVERSITAS'.split(' ').map(o => ({
	key: _.snakeCase(o).toUpperCase(),
	value: o, text: o,
}));

const OPTS_WARNA = [
	{text: "Biru", value: "blue", key: "blue"},
	{text: "Hijau", value: "green", key: "green"},
	{text: "Kuning", value: "yellow", key: "yellow"},
	{text: "Merah", value: "red", key: "red"},
]
const OPTS_STATUS_SIMPAN = [
	{text: "Draft", value: "Draft", key: "Draft"},
	{text: "Rilis", value: "Rilis", key: "Rilis"},
]

const OPTS_GOLONGAN_DARAH = [
	{ key: "A1", text: "A", value: "A" },
	{ key: "B1", text: "B", value: "B" },
	{ key: "AB1", text: "AB", value: "AB" },
	{ key: "O1", text: "O", value: "O" },
]


const OPTS_KEWARGANEGARAAN = [
	{ key: 'WNI' , text: 'Warga Negara Indonesia', value: 'Warga Negara Indonesia' },
	{ key: 'WNA' , text: 'Warga Negara Asing', value: 'Warga Negara Asing' },
]

const OPTS_PENERANGAN_RUMAH =[
	{ key: "Baik Sekali", value: "Baik Sekali", text: "Baik Sekali" },
	{ key: "Baik", value: "Baik", text: "Baik" },
	{ key: "Kurang", value: "Kurang", text: "Kurang" },
	{ key: "Sangat Kurang", value: "Sangat Kurang", text: "Sangat Kurang" },
]

const OPTS_LINGKUNGAN_RUMAH = [
	{ key: "Perumahan", value: "Perumahan", text: "Perumahan" },
	{ key: "Perkantoran", value: "Dekat Perkantoran", text: "Dekat Perkantoran" },
	{ key: "Jalan Raya", value: "Dekat Jalan Raya", text: "Dekat Jalan Raya" },
]

const OPTS_STATUS_RUMAH = [
	{ key: "Orang Tua", value: "Rumah Pribadi/ bersama Orang Tua", text: "Rumah Pribadi/ bersama Orang Tua" },
	{ key: "Kerabat", value: "Rumah Kerabat", text: "Rumah Kerabat" },
	{ key: "Kos/ Kontrak", value: "Kos/ Kontrak", text: "Kos/ Kontrak" },
]

const OPTS_STATUS_PERNIKAHAN = [
	{ key: "Belum Menikah", value: "Belum Menikah", text: "Belum Menikah" },
	{ key: "Menikah", value: "Menikah", text: "Menikah" },
	{ key: "Duda/Janda", value: "Duda/Janda", text: "Duda/Janda" },
]

const OPTS_TINGKAT_PT = [
	{key: "D1", text: "D1", value: "D1"},
	{key: "D2", text: "D2", value: "D2"},
	{key: "D3", text: "D3", value: "D3"},
	{key: "D4", text: "D4", value: "D4"},
	{key: "S1", text: "S1", value: "S1"},
	{key: "S2", text: "S2", value: "S2"},
	{key: "S3", text: "S3", value: "S3"},
]

const OPTS_JENIS_UNIT = [
	{key: "Unit Pendidikan", text: "Unit Pendidikan", value: "Unit Pendidikan"},
	{key: "Unit Non Pendidikan", text: "Unit Non Pendidikan", value: "Unit Non Pendidikan"},
]

const OPTS_AGAMA = [
	{ key:  'Islam', text: 'Islam', value: 'Islam'},
	{ key:  'Kristen', text: 'Protestan', value: 'Protestan'},
	{ key:  'Katolik', text: 'Katolik', value: 'Katolik'},
	{ key:  'Hindu', text: 'Hindu', value: 'Hindu'},
	{ key:  'Buddha', text: 'Buddha', value: 'Buddha'},
	{ key:  'Konghucu', text: 'Konghucu', value: 'Konghucu'},
	{ key:  'Kepercayaan', text: 'Kepercayaan', value: 'Kepercayaan'},
]

const OPTS_PERIODE = 'SEMESTER INI-BULAN INI-MINGGU INI-HARI INI'.split('-').map(o => ({
	key: _.snakeCase(o).toUpperCase(),
	value: _.snakeCase(o).toUpperCase(),
	text: o.toLowerCase().split(' ').map(_.upperFirst).join(' '),
}));

const OPTS_TINGKAT = [
	{ key: 'TINGKAT_KABUPATENKOTA', value: "Kabupaten/Kota", text: "Kabupaten/Kota" },
	{ key: 'TINGKAT_PROVINSI', value: "Provinsi", text: "Provinsi" },
	{ key: 'TINGKAT_NASIONAL', value: "Nasional", text: "Nasional" },
	{ key: 'TINGKAT_INTERNASIONAL', value: "Internasional", text: "Internasional" },
];

const OPTS_JENIS_KARYA = [
	{ key: 'ARTIKEL', value: "Artikel", text: "Artikel" },
	{ key: 'BUKU', value: "Buku", text: "Buku" },
	{ key: 'MAJALAH', value: "Majalah", text: "Majalah" },
	{ key: 'LAIN_LAIN', value: "Lain-lain", text: "Lain-lain" },
];
const OPTS_JABATAN_EKTRAKURIKULER = [
	{ key: 'KETUA', value: "KETUA", text: "Ketua" },
	{ key: 'ANGGOTA', value: "ANGGOTA", text: "Anggota" },
];

const OPTS_NAMA_ORGANISASI = [
	{ key: "ORG", value: "ORG", text: "ORG" },
];

const OPTS_NILAI_PREDIKAT = [
	{ key: "A", value: "A", text: "A", content: "A" },
	{ key: "B", value: "B", text: "B", content: "B" },
	{ key: "C", value: "C", text: "C", content: "C" },
	{ key: "D", value: "D", text: "D", content: "D" },
	{ key: "E", value: "E", text: "E", content: "E" },
];

const OPTS_STATUS_VAKSIN = [
	{ key: "Belum", value: "Belum", text: "Belum"},
	{ key: "Vaksin 1", value: "Vaksin 1", text: "Vaksin 1"},
	{ key: "Vaksin 2", value: "Vaksin 2", text: "Vaksin 2"},
	{ key: "Vaksin 3", value: "Vaksin 3", text: "Vaksin 3"},
];

const OPTS_RENTAN_UMUR = [
	{ key: "20", value: "<20", text: "<20" },
	{ key: "21-30", value: "21-30", text: "21-30" },
	{ key: "31-40", value: "31-40", text: "31-40" },
	{ key: "41-50", value: "41-50", text: "41-50" },
	{ key: "50", value: ">50", text: ">50" },
];

const OPTS_JENIS_SK = [
	{ key: "Status Pegawai", value: "Status Pegawai", text: "Status Pegawai" },
	{ key: "Jabatan", value: "Jabatan", text: "Jabatan" },
	{ key: "Pangkat", value: "Pangkat", text: "Pangkat" },
	{ key: "Golongan", value: "Golongan", text: "Golongan" },
];

const OPTS_PERSEN_KELENGKAPAN = [
	{ key: "10%", value: "<10%", text: "<10%", upper: 10 },
	{ key: "10-25%", value: "10-25%", text: "10-25%", upper: 25, lower: 10 },
	{ key: "26-50%", value: "26-50%", text: "26-50%", upper: 50, lower: 26 },
	{ key: "51-75%", value: "51-75%", text: "51-75%", upper: 75, lower: 51 },
	{ key: "76-99%", value: "76-99%", text: "76-99%", upper: 99, lower: 76 },
	{ key: "100%", value: "100%", text: "100%", lower: 100,},
];

const OPTS_JENIS_PRESTASI = [
	{ key: "PRESTASI_LOMBA", value: "LOMBA", text: "Lomba" },
	{ key: "PRESTASI_ORGANISASI", value: "ORGANISASI", text: "Organisasi" },
	{ key: "PRESTASI_EKSTRAKURIKULER", value: "EKSTRAKURIKULER", text: "Ekstrakurikuler" },
	{ key: "PRESTASI_AKADEMIK", value: "AKADEMIK", text: "Akademik" },
	{ key: "PRESTASI_LAINNYA", value: "PRESTASI_LAINNYA", text: "Prestasi Lainnya" },
];

const OPTS_TUJUAN_PENGUMUMAN = [
	{key: "murid", value: "murid", text: "Murid" },
	{key: "wali_murid", value: "wali_murid", text: "Wali Murid" },
	{key: "karyawan", value: "karyawan", text: "Karyawan" }
]
const OPTS_ORTU = [
	{key: "Ayah", value: "ayah", text: "Ayah" },
	{key: "Ibu", value: "ibu", text: "Ibu" },
	{key: "Wali", value: "wali", text: "Wali" }
]
const OPTS_ALAMAT_KTP = [
	{key: "Sama Seperti Alamat Tinggal Saya", value: true, text: "Sama Seperti Alamat Tinggal Saya" },
	{key: "Berbeda", value: false, text: "Berbeda" }
]
const OPTS_TIPE_NILAI_PENGETAHUAN = [
	// { key: "TLS", value: "TLS", text: "TLS", content: "Tulis" },
	// { key: "LSN", value: "LSN", text: "LSN", content: "Lisan" },
	{ key: "TGS", value: "TGS", text: "TGS", content: "Tugas" },
	{ key: "ULG", value: "ULG", text: "ULG", content: "Ulangan" },
];

const OPTS_KOTA_KAB = [
	{key: "Kabupaten", value: "Kabupaten", text: "Kab."},
	{key: "Kota", value: "Kota", text: "Kota"}
]

const OPTS_TIPE_NILAI_SIKAP = [
	{ key: "OBS", value: "OBSERVASI", text: "Observasi", content: "Observasi" },
	{ key: "DRI", value: "DIRI", text: "Diri", content: "Diri" },
	{ key: "ATR", value: "ANTAR TEMAN", text: "Teman", content: "Antar Teman" },
	{ key: "JRL", value: "JURNAL", text: "Jurnal", content: "Jurnal" },
];

const OPTS_TIPE_NILAI_KETERAMPILAN = [
	{ key: "PTK", value: "PTK", text: "PTK", content: "Praktik" },
	{ key: "PRD", value: "PRD", text: "PRD", content: "Produk" },
	{ key: "PRT", value: "PRT", text: "PRT", content: "Portofolio" },
	{ key: "PRY", value: "PRY", text: "PRY", content: "Proyek" },
];

const OPTS_JALUR_MASUK = [
	{ key: "PRESTASI", value: "PRESTASI", text: "Prestasi" },
	{ key: "PRESTASI_2", value: "PRESTASI_2", text: "Prestasi 2" },
	{ key: "REGULER", value: "REGULER", text: "Reguler" },
	{ key: "REGULER_2", value: "REGULER_2", text: "Reguler 2" },
	{ key: "REGULER_2", value: "REGULER_3", text: "Reguler 3" },
]

const OPTS_PENDIDIKAN = "Tidak/Belum_PAUD_SD_SMP_SMA_SMK_D3_D4_S1_S2_S3".split("_").map(v => ({
	key: v, value: v, text: v
}));

const OPTS_STATUS_ANAK = "Anak Kandung_Anak Angkat_Anak Tiri".split("_").map(v => ({
	key: v, value: v, text: v
}));

const OPTS_DATA_KEPEGAWAIAN = _.chain(LIST_VARIABEL).filter(o => !_.includes(["{{sk_nomor}}", "{{tanggal_mulai}}", "{{tanggal_selesai}}", "{{sk_judul}}", "{{ttd_kota}}", "{{ttd_tanggal_masehi}}",  "{{ttd_tanggal_hijriah}}", "{{ttd_nama}}", "{{ttd_jabatan}}", "{{daftar_tembusan}}]"], o.kode)).map(v => ({
	key: v.kode, value: v.kode, text: v.label
})).value()

const OPTS_COLOR = ["purple", "blue", "teal", "green", "yellow", "orange", "red"]

const OPTS_KELAS_TINGKAT = "T1 T2 T3".split(" ").map(v => ({ key: v, text: `Tingkat ${v}`, value: v }));
const OPTS_KELAS_PROGRAM = "P1 P2".split(" ").map(v => ({ key: v, text: v, value: v }));

const IMAGE_SMALL = 100;
const IMAGE_MEDIUM = 150;
const IMAGE_HIGH = 300;

const POIN_LIMIT = 0;
const BATAS_POIN_PELANGGARAN = 500;
const OPTS_TATIB_WAKTU = OPTS_PERIODE;

const OPTIONS = {
	OPTS_WARNA,
	OPTS_COLOR,
	OPTS_AGAMA,
	OPTS_TINGKAT,
	OPTS_PERIODE,
	OPTS_PENDIDIKAN,
	OPTS_TATIB_WAKTU,
	OPTS_JENIS_PRESTASI,
	OPTS_JENIS_KELAMIN,
	OPTS_STATUS_SIMPAN,
	OPTS_KOTA_KAB,
	OPTS_JENJANG_PENDIDIKAN,
	OPTS_NAMA_ORGANISASI,
	OPTS_PENERANGAN_RUMAH,
	OPTS_JABATAN_EKTRAKURIKULER,
	OPTS_TIPE_NILAI_PENGETAHUAN,
	OPTS_TIPE_NILAI_SIKAP,
	OPTS_KEWARGANEGARAAN,
	OPTS_STATUS_ANAK,
	OPTS_TIPE_NILAI_KETERAMPILAN,
	OPTS_NILAI_PREDIKAT,
	OPTS_KELAS_TINGKAT,
	OPTS_STATUS_RUMAH, 
	OPTS_LINGKUNGAN_RUMAH,
	OPTS_KELAS_PROGRAM,
	OPTS_JALUR_MASUK,
	OPTS_STATUS_PERNIKAHAN,
	OPTS_ALAMAT_KTP,
	OPTS_TUJUAN_PENGUMUMAN,
	OPTS_ORTU,
	OPTS_GOLONGAN_DARAH,
	OPTS_TINGKAT_PT,
	OPTS_JENIS_KARYA,
	OPTS_PERSEN_KELENGKAPAN,
	OPTS_RENTAN_UMUR,
	OPTS_JENIS_SK,
	OPTS_DATA_KEPEGAWAIAN,
	OPTS_JENIS_UNIT,
	OPTS_STATUS_PEGAWAI,
	OPTS_GOLONGAN_RUANG,
	OPTS_PANGKAT,
	OPTS_TUGAS_POKOK,
	OPTS_TUGAS_TAMBAHAN,
	OPTS_STATUS_VAKSIN,
};

const SURAT = {
	history: [
		{ icon: "file outline", color: "grey" },
		{ icon: "dot circle outline", color: "green" },
		{ icon: "envelope outline", color: "green" },
		{ icon: "envelope open outline", color: "green" },
		{ icon: "clipboard check", color: "green" },
		{ icon: "clipboard check", color: "green" },
		{ icon: "clipboard check", color: "green" },
		{ icon: "hand point right outline", color: "green" },
		{ icon: "hand point right outline", color: "green" },
		{ icon: "flag checkered", color: "green" },
		{ icon: "inbox", color: "blue" },
		{ icon: "inbox", color: "blue" },
		{ icon: "inbox", color: "blue" },
		{ icon: "exclamation circle", color: "orange" },
		{ icon: "exclamation circle", color: "orange" },
		{ icon: "exclamation circle", color: "orange" },
		{ icon: "hand point right outline", color: "yellow" },
		{ icon: "exclamation circle", color: "yellow" },
		{ icon: "flag checkered", color: "yellow" },
		{ icon: "envelope open outline", color: "yellow" },
		{ icon: "clipboard check", color: "yellow" },
	],
	sidebar: [
		{ icon: "paper plane outline", color: "blue" },
		{ icon: "arrow alternate circle up outline", color: "blue" },
		{ icon: "inbox", color: "blue" },
		{ icon: "envelope open outline", color: "yellow" },
		{ icon: "clipboard check", color: "yellow" },
		{ icon: "mail outline", color: "green" },
		{ icon: "dot circle outline", color: "green" },
		{ icon: "envelope open outline", color: "green" },
		{ icon: "hand point right outline", color: "green" },
		{ icon: "clipboard check", color: "green" },
		{ icon: "flag checkered", color: "green" },
	]
};

export {
	NAMA_SEKOLAH,
	CONTACT_IMPULS,
	OPTIONS,
	POIN_LIMIT,
	BATAS_POIN_PELANGGARAN,
	LOGIN_FORM,
	FORGET_FORM,
	DEFAULT_KKM,
	DEFAULT_DIFF,
	MAP_LEVEL,
	MAX_FILE_UPLOAD_SIZE,
	IMAGE_SMALL,
	IMAGE_MEDIUM,
	IMAGE_HIGH,
	JENJANG,
	JENJANG_SEBELUMNYA,
	JENJANG_SEBELUMNYA_KECIL,
	LIST_VARIABEL,
	TEMPLATE_SK_UNIT,
	SURAT
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ nama = "", handleLogout = () => "" } = {}) => {
	const list =  [
		/**
		 * KARYAWAN
		 */	
		{ id: "DASHBOARD", logo: "table", menu: "Dashboard", to: "/dashboard" },

		{ id: "PROFIL", logo: "user circle", menu: "Profil Anda"},
		{ parent: "PROFIL", id: "PROFIL#BIODATA", menu: "Biodata Anda", to: "/profil/biodata" },
		{ parent: "PROFIL", id: "PROFIL#KEPEGAWAIAN", menu: "Kepegawaian Anda", to: "/profil/kepegawaian" },
		
		{ id: "PEGAWAI", logo: "users", menu: "Kepegawaian"},
		{ parent: "PEGAWAI", id: "PEGAWAI#D_BIO_PEGAWAI", menu: "Biodata Pegawai", to: "/kepegawaian/biodata" },
		{ parent: "PEGAWAI", id: "PEGAWAI#D_RIWAYAT_PEGAWAI", menu: "Riwayat Kepegawaian", to: "/kepegawaian/riwayat" },
		{ parent: "PEGAWAI", id: "PEGAWAI#D_ARSIP_PEGAWAI", menu: "Arsip Pegawai", to: "/kepegawaian/arsip" },

		{ id: "PRESENSI", logo: "tasks", menu: "Presensi", to: "/presensi"},
		{ parent: "PRESENSI", id: "PRESENSI#D_PRESENSI", menu: "Daftar Presensi", to: "/presensi/daftar" },
		{ parent: "PRESENSI", id: "PRESENSI#D_PEGAWAI", menu: "Presensi Pegawai", to: "/presensi/pegawai" },
		
		{ id: "SURAT", logo: "mail", menu: "Surat Menyurat", to: "/surat"},
		{ parent: "SURAT", id: "SURAT#SURAT_ANDA", menu: "Surat Anda", to: "/surat/surat-anda" },
		{ parent: "SURAT", id: "SURAT#SURAT_MASUK", menu: "Surat Masuk", to: "/surat/surat-masuk" },
		{ parent: "SURAT", id: "SURAT#SURAT_KELUAR", menu: "Surat Keluar", to: "/surat/surat-keluar" },
		{ parent: "SURAT", id: "SURAT#DSURAT", menu: "Daftar Surat", to: "/surat/daftar-surat" },

		{ id: "ATUR", logo: "cog", menu: "Pengaturan Sistem"},
		{ parent: "ATUR", id: "ATUR#D_PEGAWAI", menu: "Daftar Akun Pegawai", to: "/pengaturan-sistem/akun-pegawai" },
		{ parent: "ATUR", id: "ATUR#KUNCI", menu: "Penguncian Akses", to: "/pengaturan-sistem/penguncian-akses" },
		{ parent: "ATUR", id: "ATUR#PRESENSI", menu: "Pengaturan Presensi", to: "/pengaturan-sistem/pengaturan-presensi" },
		{ parent: "ATUR", id: "ATUR#YAYASAN", menu: "Pengelolaan Yayasan", to: "/pengaturan-sistem/pengelolaan-yayasan" },
		{ parent: "ATUR", id: "ATUR#D_UNIT", menu: "Pengelolaan Unit", to: "/pengaturan-sistem/pengelolaan-unit" },
		{ parent: "ATUR", id: "ATUR#D_TEMPLATE", menu: "Template SK", to: "/pengaturan-sistem/template-sk" },
		{ parent: "ATUR", id: "ATUR#VARIABEL", menu: "Pengaturan Variabel", to: "/pengaturan-sistem/pengaturan-variabel" },
		/**
		 * Generic
		 */
		{ id: "AKSI", menu: nama },
		{ parent: "AKSI", id: "AKSI#KELUAR", menu: "Keluar / Log Out", onClick: handleLogout },
		//========================================================================
	];
	return list;
}
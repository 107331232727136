import React, { useState, useEffect, useCallback} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';

import Login from './views/Login/Index';
import Dashboard from './views/Dashboard';

import generateListMenu from './daftar_menu.js';
import routes from './routes';
import Theming from './Theming';
import { UserContext } from './context';
import { validate, logout, surat } from './fetcher';
import { OPTIONS, LIST_VARIABEL } from './helpers/constant';

const buildMenu = (list_menu, peran, acl, item_surat, sk_update) => {
	let allowed = _.intersectionBy(list_menu, acl, 'id')
	let list_parent = _.chain(allowed).map(o => _.first(o.id.split('#'))).uniq().compact().value();
	list_parent = list_menu.filter(o => _.includes(list_parent, o.id));
	allowed = _.groupBy(allowed, 'parent');
	
	const retval = _.chain(list_parent).map(o => {
		const child = _.get(allowed, o.id, []).map(o => _.omit(o, 'parent'));
		const item = {
			child,
			parent: _.assign({ to: _.chain(child).first().get('to', '').value() }, o),
		};
		return item;
	}).value();

	const incSur = retval.map((item) => item?.parent?.id === "SURAT" ? ({
		parent: {
			...item?.parent || {},
			menu: `${item?.parent?.menu || ""} ${(item_surat?.sidebar_anda?.total || 0) !== 0 ? `(${item_surat?.sidebar_anda?.total || "0"})` : ""}`
		},
		child: (item?.child || []).map((item) => ({
			...item,
			menu: `${item?.menu || ""} ${(item?.menu !== "Daftar Surat" && item_surat?.sidebar_anda?.[(item?.menu || "").replaceAll(" ", "_").toLowerCase()] !== 0) ? `(${item_surat?.sidebar_anda?.[(item?.menu || "").replaceAll(" ", "_").toLowerCase()]})` : ""}`
		}))
	}) : item);

	const incKep = incSur.map((item) => item?.parent?.id === "PEGAWAI" ? ({
		parent: {
			...item?.parent || {},
			menu: `${item?.parent?.menu || ""} ${(sk_update || 0) !== 0 ? `(${sk_update || "0"})` : ""}`
		},
		child: (item?.child || []).map((item) => ({
			...item,
			menu: `${item?.menu || ""} ${(item?.menu === "Riwayat Kepegawaian" && sk_update !== 0) ? `(${sk_update})` : ""}`
		}))
	}) : item);

	return incKep;
}

const App = (props) => {
	const [session, setSession]		= useState({});
	const [isLoading, setLoading] = useState(true);
	const [sideSurat, setSideSurat] = useState(null);

	const updateSideSurat = useCallback(async () => {
		try {
			const { status_code, result } = await surat.sidebarMenu();

			status_code === 200 && setSideSurat(result)
		} catch (err) {
			console.log(err)
		}
	}, []);

	const validateUser = useCallback(async () => {
		setLoading(true);
		let is_login = false
		let list_admin_sistem = [] 
		const fetch = await validate()
		const status_code = _.get(fetch, "status_code", 0)
		const session = _.get(fetch, 'result', {});
		const { user, acl } = session;

		if(status_code < 400){ is_login = true }
		if(status_code > 400){ list_admin_sistem = _.get(fetch, "data.list_admin_sistem", []) }
		const list_acl 	= _.filter(acl, o => !_.isEmpty(o.level));
		const map_acl 	= _.chain(list_acl).groupBy("id").mapValues(arr => _.assign(
			{}, _.last(arr),
			{ level: _.chain(arr).reduce((sum, o) => sum + _.get(o, 'level', ''), '').split('').uniq().compact().value().join('') },
		)).value();	
		_.set(session, "list_acl", list_acl);
		_.set(session, "map_acl", map_acl);
		_.set(session, "is_login", is_login);
		_.set(session, "list_admin_sistem", list_admin_sistem);

		if (is_login) {
			const nama 			= user?.nama || '-';
			const user_id		= user?._id;
			const periode_id = session?.periode_id;
			const periode = session?.periode;
			const all_menu	= generateListMenu({
				nama,
				handleLogout: async () => {
					await logout();
					await validateUser();
				},
			});

			const list_menu = buildMenu(all_menu, session.peran, session.acl, session.item_surat, session.sk_update);
			const list_route = routes
			_.set(session, "list_route", list_route);
			_.set(session, "user_id", user_id);
			_.set(session, "list_menu", list_menu);
			_.set(session, "list_templat_variabel", LIST_VARIABEL);
			_.set(session, "list_warna", OPTIONS.OPTS_COLOR);
			_.set(session, "periode_id", periode_id);
			_.set(session, "periode", periode);
		}
 
		setSession(session);
		return setLoading(false);
	}, []);

	useEffect(() => {
		validateUser();
	}, [updateSideSurat, validateUser]);
	
	return (<UserContext.Provider value={_.assign({ validateUser, sideSurat, updateSideSurat }, session)}>
		<BrowserRouter>
			{isLoading ?  <Loader/> : <Switch>
				<Route path='/login' component={Login} />
				<Route path='/theming' component={Theming} />
				<Route path='/' component={Dashboard} />
			</Switch>}
		</BrowserRouter>
	</UserContext.Provider>);
};

export default App;